import React, {useEffect, useState} from 'react';
import {Button, List, Modal, notification, Space, Spin} from "antd";
import {deleteServer, fetchServers} from "./serversAPI";
import {useNavigate} from "react-router";

const isAdmin = () => localStorage.getItem('role') === 'admin'

export const Servers = () => {
  const [initLoading, setInitLoading] = useState(true);
  const [data, setData] = useState([]);

  const reloadServers = () => {
    setInitLoading(true);
    fetchServers()
      .then(setData)
      .catch(() => {
        setData([]);
        notification.error({
          message: 'Failed to fetch servers',
        })
      })
      .finally(() => {
        setInitLoading(false);
      });
  };

  useEffect(() => {
    reloadServers()
  }, []);

  const onEdit = (item) => {
    navigate(`/servers/edit/${item.id}`);
  };

  const onDeleteAlert = (item, onDelete) => {
    Modal.confirm({
      title: 'Are you sure delete this server?',
      content: <>{`Server name: ${item.name}`}<br/>{`Address: ${item.address}`}</>,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        onDelete(item);
      },
    });
  };

  const onDelete = (item) => {
    setInitLoading(true);
    deleteServer(item.id)
      .then(() => {
        reloadServers();
      })
      .catch(() => {
        notification.error({
          message: 'Failed to delete server',
        })
      })
      .finally(() => {
        setInitLoading(false);
      })
  };

  const navigate = useNavigate();
  const onCreate = () => {
    navigate('/servers/add');
  };

  return <Spin spinning={initLoading}>
    <Space wrap>
      <Button type="primary" onClick={onCreate} disabled={localStorage.getItem('role') !== 'admin'} >Add server</Button>
    </Space>
    <List
      className="demo-loadmore-list"
      loading={initLoading}
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item) => {

        return <List.Item
          actions={isAdmin() ? [
            <a onClick={() => onEdit(item) } key="list-loadmore-edit">edit</a>,
            <a onClick={() => onDeleteAlert(item, onDelete) } key="list-loadmore-more">delete</a>,
          ] : []}
        >
          <List.Item.Meta
            title={`Server name: ${item.name}`}
            description={`Address: ${item.address}`}
          />
        </List.Item>
      }}
    />
  </Spin>;
}

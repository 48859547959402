import React, {useEffect, useState} from 'react';
import {Button, List, Modal, notification, Space, Spin} from "antd";
import { deleteUser, fetchUsers } from "./usersAPI";
import {useNavigate} from "react-router";


export const Users = () => {
  const [initLoading, setInitLoading] = useState(true);
  const [data, setData] = useState([]);

  const reloadUsers = () => {
    setInitLoading(true);
    fetchUsers()
      .then(setData)
      .catch((error) => {
        setData([]);

        if (error.response?.status === 403) {
          navigate('/');
        } else {
          notification.error({
            message: 'Failed to fetch users',
          })
        }
      })
      .finally(() => {
        setInitLoading(false);
      });
  };

  useEffect(() => {
    reloadUsers()
  }, []);

  const onEdit = (item) => {
    navigate(`/users/edit/${item.id}`);
  };

  const onDeleteAlert = (item, onDelete) => {
    Modal.confirm({
      title: 'Are you sure delete this user?',
      content: <>{`User name: ${item.username}`}<br/>{`Role: ${item.role}`}</>,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        onDelete(item);
      },
    });
  };

  const onDelete = (item) => {
    setInitLoading(true);
    deleteUser(item.id)
      .then(() => {
        reloadUsers();
      })
      .catch(() => {
        notification.error({
          message: 'Failed to delete user',
        })
      })
      .finally(() => {
        setInitLoading(false);
      })
  };

  const navigate = useNavigate();
  const onCreate = () => {
    navigate('/users/add');
  };

  return <Spin spinning={initLoading}>
    <Space wrap>
      <Button type="primary" onClick={onCreate} >Add user</Button>
    </Space>
    <List
      className="demo-loadmore-list"
      loading={initLoading}
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item) => {

        return <List.Item
          actions={[<a onClick={() => onEdit(item) } key="list-loadmore-edit">edit</a>, <a onClick={() => onDeleteAlert(item, onDelete) } key="list-loadmore-more">delete</a>]}
        >
          <List.Item.Meta
            title={`User: ${item.username}`}
            description={`Role: ${item.role}`}
          />
        </List.Item>
      }}
    />
  </Spin>;
}

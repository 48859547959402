import { Button, Checkbox, Dropdown, Form, Input, Select } from "antd";
import React, { useState } from "react";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const initialData = {
  name: 'New server name',
  address: 'https://',
  token: '',
  users: [],
  all_users: false,
};

export const ServerEditForm = ({ onUpdate, data, isCreate, users }) => {
  if (!isCreate && !data) {
    return <></>;
  }

  const usersOptions = users.map(user => {
    return {
      label: user.username,
      value: user.id,
    }
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [allUsers, setAllUsers] = useState(data?.all_users);

  return <Form
    { ...layout }
    name="nest-messages"
    onFinish={onUpdate}
    style={{ maxWidth: 600 }}
    initialValues={isCreate ? initialData : data}
  >
    <Form.Item name={['name']} label="Server name" rules={[{ min: 1, required: true }]} >
      <Input />
    </Form.Item>
    <Form.Item name={['address']} label="Server API" rules={[{ min: 1, required: true }]}>
      <Input />
    </Form.Item>
    <Form.Item name={['token']} label="Server token" rules={[{ min: 1, required: true }]}>
      <Input />
    </Form.Item>
    <Form.Item name={['users']} label="Account Assignment" >
      <Select
        disabled={allUsers}
        mode="multiple"
        placeholder="Please select"
        options={usersOptions}
      />
    </Form.Item>
    <Form.Item name={['all_users']} label="All accounts" valuePropName={'checked'} >
      <Checkbox
        onChange={(e) => {
          setAllUsers(e.target.checked);
          e.preventDefault();
        }}
      />
    </Form.Item>
    <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
      <Button type="primary" htmlType="submit">
        {!isCreate ? 'Update' : 'Add server'}
      </Button>
    </Form.Item>
  </Form>
};

import {Navigate, RouterProvider} from "react-router";
import {createBrowserRouter} from "react-router-dom";
import Root from "./root";
import ActionsPage from "./actions";
import ActionPage from "./action";
import GeneralPage from "./general";
import ActionNewPage from "./action-new";
import BackupsPage from "./backups";
import LoginPage from "./login";
import ServersPage from "./servers";
import ServersEditPage from "./servers-edit";
import ServerPage from "./server";
import UsersPage from "./users";
import LogoutPage from "./logout";
import UserPage from "./user";
import UsersAdd from "./users-add";
import UserAddPage from "./users-add";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        path: "/",
        element: <Navigate to={'/servers'} />
      },
      {
        path: "/general",
        element: <GeneralPage />
      },
      {
        path: "/actions",
        element: <ActionsPage />,
      },
      {
        path: "/actions/edit/:action",
        element: <ActionPage />
      },
      {
        path: "/actions/new",
        element: <ActionNewPage />
      },
      {
        path: "/backups",
        element: <BackupsPage />
      },
      {
        path: "/servers",
        element: <ServersPage />
      },
      {
        path: "/servers/add",
        element: <ServersEditPage />
      },
      {
        path: "/servers/edit/:serverId",
        element: <ServerPage />
      },
      {
        path: "/users",
        element: <UsersPage />
      },
      {
        path: "/users/add",
        element: <UserAddPage />
      },
      {
        path: "/users/edit/:userId",
        element: <UserPage />
      },
      {
        path: "/logout",
        element: <LogoutPage />
      }
    ],
  },
  {
    "path": "/login",
    "element": <LoginPage />
  }
]);

export const Routing = () => {
  return (
    <RouterProvider router={router} />
  );
};

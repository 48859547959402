import {fetchApi} from "../fetch-api";

export const fetchUsers = async () => {
  const result = await fetchApi().get('/users');
  return result.data;
}

export const fetchUser = async (userId) => {
  const result = await fetchApi().get(`/users/${userId}`);
  return result.data;
}

export const deleteUser = async (userId) => {
  await fetchApi().delete(`/users/${userId}`);
}

export const createUser = async (data) => {
  const result = await fetchApi().post('/users', data);
  return result.data;
}

export const updateUser = async (userId, data) => {
  const result = await fetchApi().put(`/users/${userId}`, data);
  return result.data;
}

import {ServerEditForm} from "./ServerEditForm";
import {useEffect, useState} from "react";
import {notification, Spin} from "antd";
import {createServer, fetchServer, updateServer} from "./serversAPI";
import {useNavigate} from "react-router";
import { fetchUsers } from "../users/usersAPI";

export const ServerEdit = ({ serverId }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const [users, setUsers] = useState([])
  useEffect(() => {
    fetchUsers()
      .then(setUsers)
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchServer(serverId)
      .then(setData)
      .catch(() => {
        notification.error({
          message: 'Failed to fetch server',
        });
        navigate('/servers');
      })
      .finally(() => setLoading(false))
  }, [serverId]);

  const onUpdate = (data) => {
    setLoading(true);
    updateServer(serverId, data)
      .then(() => {
        notification.info({
          message: 'Server updated'
        })
      })
      .catch(() => {
        notification.error({
          message: 'Failed to update server',
        });
      })
      .finally(() => setLoading(false));
  };

  return <Spin spinning={loading}>
    <ServerEditForm data={data} isCreate={false} onUpdate={onUpdate} users={users} />
  </Spin>
};

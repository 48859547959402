import { Button, Dropdown, Form, Input, Select } from "antd";
import React from "react";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const initialData = {
  username: '',
  password: '',
  role: 'user'
};

export const UserEditForm = ({ onUpdate, data, isCreate }) => {
  if (!isCreate && !data) {
    return <></>;
  }
  return <Form
    { ...layout }
    name="nest-messages"
    onFinish={onUpdate}
    style={{ maxWidth: 600 }}
    initialValues={isCreate ? initialData : data}
  >
    <Form.Item name={['username']} label="Username" rules={[{ min: 1, required: !!isCreate }]} >
      <Input />
    </Form.Item>
    <Form.Item name={['password']} label="Password" rules={[{ type: "password", min: 1, required: !!isCreate }]}>
      <Input.Password />
    </Form.Item>
    <Form.Item name={['role']} label="Role" >
      <Select
        placeholder="Please select"
        options={[{label: 'user', value: 'user'}, {label: 'admin', value: 'admin'}]}
      />
    </Form.Item>
    <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
      <Button type="primary" htmlType="submit">
        {!isCreate ? 'Update' : 'Add user'}
      </Button>
    </Form.Item>
  </Form>
};

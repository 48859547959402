import {ServerEditForm} from "./ServerEditForm";
import { useEffect, useState } from "react";
import {notification, Spin} from "antd";
import {createServer} from "./serversAPI";
import {useNavigate} from "react-router";
import { fetchUsers } from "../users/usersAPI";

export const ServerAdd = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([])
  useEffect(() => {
    fetchUsers()
      .then(setUsers)
  }, []);

  const onUpdate = (data) => {
    setLoading(true);
    createServer(data)
      .then(() => navigate('/servers'))
      .catch(() => {
        notification.error({
          message: 'Failed to create server',
        })
      })
      .finally(() => setLoading(false));
  };

  return <Spin spinning={loading}>
    <ServerEditForm data={null} isCreate={true} onUpdate={onUpdate} users={users} />
  </Spin>
};

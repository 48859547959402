import { ServerEditForm, UserEditForm } from "./UserEditForm";
import {useEffect, useState} from "react";
import {notification, Spin} from "antd";
import { createServer, fetchServer, fetchUser, updateServer, updateUser } from "./usersAPI";
import {useNavigate} from "react-router";

export const UserEdit = ({ userId }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    setLoading(true);
    fetchUser(userId)
      .then(setData)
      .catch(() => {
        notification.error({
          message: 'Failed to fetch user',
        });
        navigate('/users');
      })
      .finally(() => setLoading(false))
  }, [userId]);

  const onUpdate = (data) => {
    setLoading(true);
    updateUser(userId, data)
      .then(() => {
        notification.info({
          message: 'User updated'
        })
      })
      .catch(() => {
        notification.error({
          message: 'Failed to update user',
        });
      })
      .finally(() => setLoading(false));
  };

  return <Spin spinning={loading}>
    <UserEditForm data={data} isCreate={false} onUpdate={onUpdate} />
  </Spin>
};
